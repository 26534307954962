import React from "react"
import { Col, Row, Container } from "reactstrap"
import { Logo } from "../../components/logo/Logo"
import Footer from "../../layout/footer/Footer"
import LogoDrak2x from '../../images/logo-dark2x.png'
import CSLogo from '../../images/CS_new_logo.png'
// import CSLogo from '../../images/cs_new_logo_trans2.png'
import LogoLight2x from '../../images/logo2x.png'
import { SocialIcon } from "../../components/icon/Icon"
import { LinkInline, LinkInlineScroll } from "../../components/styledList/StyledList"
import { SocialIconOne, LinkData } from './FooterData'
import { Link } from "react-scroll"
import '../../assets/scss/core/components/_ovm.scss'


const FooterOne = (props) => {
    return (
        <Footer className={props.className && props.className} id={props.id && props.id}>
            <Container>

                <div className="footer-box">
                    <div className="menulist">
                        <a href="/terms-and-conditions">
                            <h4>Terms and condition</h4>
                        </a>
                        <a href="privacy-policy">
                            <h4>Privacy Policy</h4>
                        </a>
                        <a href="/cookies-policy">
                            <h4>Cookies Policy</h4>
                        </a>
                        <a href="/aboutus">
                            <h4>About</h4>
                        </a>
                        <a href="/contact">

                            <h4>Contact</h4>
                        </a>

                    </div>

                    <hr className="my-5" />
                    {/* <div className="footer-text">
                        <p>
                        Global Supply Chain professional and CharterShare founder , Vinit Pednekar is an expert in global logistics, with more than 15 years of experience. He brings his unique and exceptional experience as a multicultural supply chain leader in the energy industry, coupled with his extraordinary abilities in integrating logistics and innovation to improve processes and operations.
                        </p>
                    </div> */}
                    <div className="social-media mt-3">
                     <img src="https://i.postimg.cc/vmCF4KwS/facebook.png"></img>
                    <img src="https://i.postimg.cc/SNn6r1BG/instagram.png"></img>
                    <img src="https://i.postimg.cc/C5P4KWWR/linkedin.png"></img>

                    </div>

                </div>

                <div
                    className="text-center copyright-text p-3 ">
                    © 2023 Copyright
                    <a className="text-white" href="https://mdbootstrap.com/"
                    > | Charter Share | All rights reserved.</a>

                </div>
                {/* <hr className="hr border-light mb-0 mt-n1"></hr> */}
                {/* <Row className="g-3 align-items-center justify-content-md-between py-4">
                    <Col md="8">
                        <div className="text-base">
                            Copyright &copy; 2022 Charter Share. Made by 
                            <a className="text-base fw-bold" href="https://themeforest.net/user/softnio/portfolio" target="_blank" rel="noreferrer"> Anssoft </a>
                        </div>
                    </Col>
                    <Col md="4" className="d-flex justify-content-md-end">
                        <SocialIcon data={SocialIconOne} />
                    </Col>
                </Row> */}
            </Container>

        </Footer>

    )
}

export default FooterOne
