import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import Header from '../../section/header/Header'

// import HeaderImage from "../../../src/components/images/Images";
function customclerance() {

    return (
        <>
            {/* <HeaderImage/> */}
           <Header/>
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Customs Clearance, both origin & destination</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                                <img src='https://i.postimg.cc/s24q6btW/Customs-clearance.jpg'></img>
                            </div>
                        </div>

                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Customs Clearance, both origin & destination</h3>
                                <p>
                                Import/Export Compliance regulations are ever changing and increasing in complexityManaging I/E formalities and customs clearance holds key to manage end to end delivery. Our partners are authorized to manage this critical task in most of the airports around the world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default customclerance
