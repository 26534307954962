import { API_URL } from '../Constants';
import axios from 'axios';

export const serviceApi = ()=>{
    console.log(API_URL)
    return new Promise((resolve,reject)=>{
        axios.get(API_URL+'/api/service-page')
          .then(function (response) {
            console.log(response);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
            reject(error.response);
          })
    });
}
