import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { Service, ServiceIcon, ServiceText } from "../../components/service/Service"
import { BarChart, Cloud, Dashboard } from "../../components/svg/Icon"
import { Section, SectionContant, SectionHead } from "../../layout/section/Section"
import { serviceApi } from '../../api/ServiceApi'
import Servicecard from "../../components/service/servicecard"
import { Link } from "react-router-dom";
import packaging from "../../../src/assets/images/packaging.png";
import clearance from "../../../src/assets/images/clearance.png";
import domestic from "../../../src/assets/images/domestic.jpg";
import dangerous from "../../../src/assets/images/dangerous.jpg";
import bannerback from "../../../src/assets/images/bannerback.jpg";



const ServieOne = (props) => {

    const [serviceTitle, setServiceTitle] = useState("");
    const [subTitle1, setSubTitle1] = useState("");
    const [desc1, setDesc1] = useState("");
    const [subTitle2, setSubTitle2] = useState("");
    const [desc2, setDesc2] = useState("");
    const [subTitle3, setSubTitle3] = useState("");
    const [desc3, setDesc3] = useState("");

    useEffect(() => {
        applyServiceApidata();
    }, []);

    const applyServiceApidata = () => {
        const result = serviceApi();

        result.then(success => {
            setServiceTitle(success.data.attributes.title);
            setSubTitle1(success.data.attributes.subTitle1);
            setSubTitle2(success.data.attributes.subTitle2);
            setSubTitle3(success.data.attributes.subTitle3);
            setDesc1(success.data.attributes.description1);
            setDesc2(success.data.attributes.description2);
            setDesc3(success.data.attributes.description3);

        }, error => {

        });
    }



    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container className="py-6">
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <SectionHead className="text-center">
                            <h3 className="title fw-bold">{serviceTitle}</h3>
                            <div className="heading-main">
                                <p>
                                    {desc1}
                                </p>
                            </div>
                        </SectionHead>
                    </Col>
                </Row>
                <SectionContant>
                    <Row className="g-gs">
                        <Col sm="12" lg="4">
                            {/* <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <BarChart />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">{subTitle1}</h4>
                                    <p>{desc1}</p>
                                </ServiceText>
                            </Service> */}
                            <Link to="/packing-carting">

                                <div className="card service-details" >
                                    <div className="service-image">
                                        <img className="card-img-top big" src="https://i.postimg.cc/W1xhnsy9/packing.png" alt="" />
                                    </div>

                                    <div className="card-body">
                                        <h5 className="card-title service-text">Packing & Crating </h5>
                                        {/* <p className="card-para">Most of the shippers lack packing & crating expertise, Hazmat cargo </p> */}
                                        {/* <p className="card-text service-para">Speedy and reliable charters for grounded aircraft, ensuring you’re back in the skies as soon as possible.</p>
                                    <Link to="/servicedetails/termsandconditions" className="btn btn-primary service-btn">Learn More</Link> */}
                                    </div>
                                </div>
                            </Link>




                        </Col>
                        <Col sm="12" lg="4">
                            {/* <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <Dashboard />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">{subTitle2}</h4>
                                    <p>{desc2}</p>
                                </ServiceText>
                            </Service> */}
                            <Link to="/custom-clerance">
                                <div className="card service-details" >
                                    <div className="service-image">
                                        <img className="card-img-top big" src="https://i.postimg.cc/7YfHQp0j/custom.png" alt="" />
                                    </div>

                                    <div className="card-body">
                                        <h5 className="card-title service-text">Customs Clearance, both origin & destination </h5>
                                        {/* <p className="card-para">Import/Export Compliance </p> */}
                                        {/* <p className="card-text service-para">Our expert team is fully equipped with the latest guidelines to assist you with regulations, requirements...</p>
                                    <Link to="/servicedetails/dangerousgoods" className="btn btn-primary service-btn">Learn More</Link> */}
                                    </div>
                                </div>
                            </Link>


                        </Col>
                        <Col sm="12" lg="4">
                            {/* <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <Cloud />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">{subTitle3}</h4>
                                    <p>{desc3}</p>
                                </ServiceText>
                            </Service> */}
                            <Link to="/domestic-trucking" >
                                    <div className="card service-details" >
                                <div className="service-image">
                                    <img className="card-img-top big" src="https://i.postimg.cc/YjcsRG1r/domestic.png" alt="" />
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title service-text">Domestic Trucking</h5>
                                    {/* <p className="card-para">We can not only provide you with Van loads but offer Flatbeds</p> */}
                                    {/* <p className="card-text service-para">Our advanced technology enables us to book charters based on your complex load, considering size...</p>
                                    <Link to="/servicedetails/heavy-and-oversized-cargo" className="btn btn-primary  service-btn">Learn More</Link> */}

                                </div>
                            </div>
                            </Link>
                        
                        </Col>
                        <Col sm="12" lg="6">
                            {/* <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <Cloud />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">{subTitle3}</h4>
                                    <p>{desc3}</p>
                                </ServiceText>
                            </Service> */}
                            <Link to="/inspection">
                            <div className="card service-details" >
                                <div className="service-image">
                                    <img className="card-img-top hrimg" src="https://i.postimg.cc/zDT6Dp53/inspection.png" alt="" />
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title service-text">Inspections</h5>
                                    {/* <p className="card-para">Customs clearance in certain countries can be cumbersome as cargo is subject to random physical inspection. </p> */}
                                    {/* <p className="card-text service-para">We have experience assisting with charter flights to hard-to-reach locations. No matter where your cargo needs...</p>

                                    <Link to="/servicedetails/remote-destination" className="btn btn-primary  service-btn">Learn More</Link> */}
                                </div>
                            </div>
                            </Link>
                          
                        </Col>

                        <Col sm="12" lg="6">
                            {/* <Service className="service-s1">
                                <ServiceIcon className="service-icon styled-icon styled-icon-s1">
                                    <BarChart />
                                </ServiceIcon>
                                <ServiceText>
                                    <h4 className="title">{subTitle1}</h4>
                                    <p>{desc1}</p>
                                </ServiceText>
                            </Service> */}
                            <Link to="/certificate">
                            <div className="card service-details" >
                                <div className="service-image">
                                    <img className="card-img-top hrimg" src="https://i.postimg.cc/QCNQ3XhQ/certificate.png" alt="" />
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title service-text">Certifications</h5>
                                    {/* <p className="card-para">Certain destinations require certificates like Certificate of Origin (COO), Certificate of Conformity (COC), etc</p> */}
                                    {/* <p className="card-text service-para">Our team is available 24/7 to help with your urgent air cargo charter demands...</p>

                                    <Link to="/servicedetails/time-critical" className="btn btn-primary service-btn">Learn More</Link> */}
                                </div>
                            </div>
                            </Link>
                         
                        </Col>


                    </Row>

                    {/* <div className="wrapper">
                        <div className="featuredslider">
                            <div className="card service-details" >
                                <div className="service-image">
                                    <img className="card-img-top" src="https://i.postimg.cc/fRrLKhBw/bannerback.jpg" alt="" />
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title service-text">Time-critical</h5>
                                    <p className="card-text service-para">Our team is available 24/7 to help with your urgent air cargo charter demands...</p>

                                    <Link to="/servicedetails/time-critical" className="btn btn-primary service-btn">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </SectionContant>
            </Container>
        </Section>
    )
}


export default ServieOne
