const HeaderOne = [
    
    // {
    //     text:'About Us',
    //     to:'aboutus'
    // }, 
    // {
    //     text:'Contact',
    //     to:'/contact'
    // },
   
    
    // {
    //     text:'Login',
    //     to:'https://www.chartershare.org/app/'
    // },    
  
     
]
   
   
   
const  HeaderThird =  [
    // {
    //     text:'Home',
    //     to:'/'
    // },
    // {
    //     text:'Services',
    //     to:'#service'
    // },
  
    {
        text:'Let’s Collaborate',
        to:'/lets-collaborate'
    },  
    {
        text:'Place your Bid',
        to:'/place-your-bid'
    },
   
    {
        text:'About Us',
        to:'aboutus'
    },
     {
        text:'Contact Us',
        to:'/contact'
    },
  
    
    {
        text:'Login',
        to:'/app/'
    },    
    {
        text:'Get a Quote',
        to:'/get-quote'
    }, 
]

const HeaderSec = [
    // {
    //     text:'Home',
    //     to:'/'
    // },
    // {
    //     text:'Services',
    //     to:'#service'
    // },
  
    {
        text:'Let’s Collaborate',
        to:'/lets-collaborate'
    },  
    {
        text:'Place your Bid',
        to:'/place-your-bid'
    },
   
 
    {
        text:'Get a Quote',
        to:'/get-quote'
    }, 
      {
        text:'About Us',
        to:'aboutus'
    }, 
    {
        text:'Contact',
        to:'/contact'
    },
   
    
    {
        text:'Login',
        to:'/app/'
    },  
  ]





export {HeaderOne, HeaderSec, HeaderThird}
