import React from 'react'
import { Container, Col, Row, Card } from 'reactstrap'
import { Review, ReviewText } from '../../components/review/Review'
import { Section, SectionHead } from '../../layout/section/Section'
import User from '../../components/user/User'
import User1 from '../../images/client/a.png'
import User2 from '../../images/client/b.png'

const ReviewThree = (props) => {
  return ( 
    <Section className={props.className && props.className} id={props.id && props.id}>
        <Container>
            <Row className="justify-content-lg-center text-lg-center">
                <Col lg="6" md="10">
                    <SectionHead>
                        <h2 className="title fw-bold">TESTIMONIAL</h2>
                        <p className="fs-15px">What people say about our company</p>
                    </SectionHead>
                </Col>
            </Row>
            <Row className="g-gs">
                <Col lg="6" md="6">
                    <Card className="card-shadow">
                        <div className="card-inner card-inner-lg">
                            <Review className="review-s4">
                                <User className="review-user user-s1" src={User1} name="Sameday Logistics"  />
                                <ReviewText className="text-center">
                                    <p>We are delighted to work with Cargo Share, as they introduced a real breakthrough innovation to the global air charter market, leading to utmost market transparency, a terribly fast booking process, and highly reliable and professional operations.</p>
                                </ReviewText>
                            </Review>
                        </div>
                    </Card>
                </Col>
                <Col lg="6" md="6">
                    <Card className="card-shadow">
                        <div className="card-inner card-inner-lg">
                            <Review className="review-s4">
                                <User className="review-user user-s1" src={User2} name="Sameday Logistics"  />
                                <ReviewText  className="text-center">
                                    <p>We are delighted to work with Cargo Share, as they introduced a real breakthrough innovation to the global air charter market, leading to utmost market transparency, a terribly fast booking process, and highly reliable and professional operations.</p>
                                </ReviewText>
                            </Review>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    </Section>
  )
}

export default ReviewThree
