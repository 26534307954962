import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import inspectionimg from '../../../src/assets/images/Inspections.jpg'
import Header from '../../section/header/Header'
// import HeaderImage from "../../../src/components/images/Images";
function inspections() {

    return (
        <>
            {/* <HeaderImage/> */}
            <Header />
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Inspections</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                                <img src={inspectionimg}></img>
                            </div>
                        </div>

                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Inspections</h3>
                                <p>
                                Customs clearance in certain countries can be cumbersome as cargo is subject to random physical inspection. Our partners are trained to manage such cargo inspections to ensure seamless delivery at destination.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default inspections
