import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";

// import HeaderImage from "../../../src/components/images/Images";
function agoservice() {

    return (
        <>
            {/* <HeaderImage/> */}
            <BannerOne />

            <div className='detail-page'>
                <div className='banner-section'>
                    <h3>AOG Services</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='detail-data'>
                                <div className='detail-image'>
                                    <img src='https://i.postimg.cc/QtGj4qf5/bannerback.jpg'>

                                    </img>
                                </div>
                                <div className='detail-content'>
                                    <h5>
                                        Aircraft on Ground (AOGs) are unpredictable, difficult, and costly situations that all airlines face at some point. CharterSync’s award-winning charter experts help you find a fast, effective, and reliable solution for your critical shipments, 24/7.
                                    </h5>
                                    <p>
                                        AOG situations can occur for various reasons, including malfunctioning aircraft parts, adverse weather, the replacement of key components, assistance from an aircraft engineer, or a sudden requirement for replacement crew due to an accident or illness.
                                    </p>
                                    <p>
                                        When any of these situations occur, an aircraft cannot return to service until they are repaired, inspected, and approved.
                                    </p>
                                    <h3>
                                        Meeting your needs

                                    </h3>
                                    <p>
                                        Our world-leading technology enables you to receive quotes within minutes of your request being sent, meaning we are there to help you in the shortest possible time frame.
                                    </p>

                                    <p>
                                        We provide essential AOG air charter services globally, including delivery to remote locations, the transportation of aircraft engineers, and the shipment of small parts and new engines.
                                    </p>
                                    <p>
                                        Getting your aircraft back in the air is CharterSync’s number one priority.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='details-left'>
                                <div className='details-left-data'>
                                    <div className='choose-heading'>
                                        <h4>
                                            Why choose CharterSync for your AOG charters?

                                        </h4>
                                    </div>

                                    <div className='chooseus  mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-headset'></i>
                                                    <h4>24/7 support</h4>
                                                    <p>
                                                        Collaborate with our team of charter experts, who are always on hand to provide end-to-end support for your time-critical AOG shipments.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-globe'></i>
                                                    <h4>Worldwide availability</h4>
                                                    <p>
                                                        No matter where your AOG shipment needs are located or need to get to, our expert team are well-trained on a global scale.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row2// */}
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Cost efficiency</h4>
                                                    <p>
                                                        Our team understand the pressures of critical AOG shipments and works with you to ensure you always receive a cost-effective solution for your needs.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Aircraft choice</h4>
                                                    <p>
                                                        With thousands of aircraft available to us, we’re sure we’ll always secure the right one for you.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row3 faq// */}


                                        <div className='row mt-5'>
                                            <div className='col-lg-12 col-sm-12 col-md-12'>
                                                <div className="accordion faq-main" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                What does AOG mean?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                    AOG stands for Aircraft on Ground.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                What is AOG support?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                    We know the clock is running when your aircraft is grounded for technical reasons: the problem has to be diagnosed, qualified personnel, tools and spare parts have to be provided, and you have to be approved to fly again. CharterSync are on hand to assist you with getting your engineers, crew and spare parts to your grounded aircraft in the shortest possible time!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Can I track my AOG shipment?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                    Yes! Your charter flight will be fully trackable via your CharterSync active flight dashboard. Don’t hesitate to contact your account manager if you have any questions.


                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default agoservice
