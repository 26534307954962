import React, { Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import IndexOne from "./pages/IndexOne"
import Error404Classic from "./pages/error/404-classic"
import Error404Modern from "./pages/error/404-modern"
import Error504Classic from "./pages/error/504-classic"
import Error504Modern from "./pages/error/504-modern"
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword"
import Success from "./pages/auth/Success";
import agoservice from "./components/servicedetails/agoservice";
import dangerousgoods from "./components/servicedetails/dangerousgoods";
import heavyandoversized from "./components/servicedetails/heavy-and-oversized-cargo";
import remotedestination from "./components/servicedetails/remote-destination";
import timecritical from "./components/servicedetails/time-critical";
import avemaero from "./components/servicedetails/avem-aero";
import cargoeurope from "./components/servicedetails/cargo-europe";
import aircargofacts from "./components/servicedetails/air-cargo-facts";
import Aboutusmain from "./components/servicedetails/aboutus";
import Termsandocondition from "./components/servicedetails/termsandconditions";
import Privacypolicy from "./components/servicedetails/privacy-policy";
import Cookies from "./components/servicedetails/cookies-policy";
import services from "./components/servicedetails/sevices";
import packingcrating from "./components/servicedetails/packing-carting";
import customclerance from "./components/servicedetails/custom-clerance";
import domestictrucking from "./components/servicedetails/domestic-trucking";
import inspections from "./components/servicedetails/inspection";
import certification from "./components/servicedetails/certification";
import contactus from "./components/servicedetails/contactus";
import letscollaborate from "./components/servicedetails/lets-collaborate";
import placeyourbid from "./components/servicedetails/place-your-bid";
import getquote from "./components/servicedetails/get-a-quote";


function App() {
  return (
    <Suspense fallback={<div />}>
      <Router basename="/">
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/pages/auths/auth-login`} component={Login} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/auths/auth-register`} component={Register} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/auths/auth-forgotpassword`} component={ForgotPassword} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/auths/auth-success`} component={Success} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/error/404-classic`} component={Error404Classic} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/error/404-modern`} component={Error404Modern} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/error/504-classic`} component={Error504Classic} exact />
          <Route path={`${process.env.PUBLIC_URL}/pages/error/504-modern`} component={Error504Modern} exact />
          <Route path={`${process.env.PUBLIC_URL}/`} component={IndexOne} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/agoservice`} component={agoservice} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/dangerousgoods`} component={dangerousgoods} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/heavy-and-oversized-cargo`} component={heavyandoversized} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/remote-destination`} component={remotedestination} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/time-critical`} component={timecritical} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/avem-aero`} component={avemaero} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/cargo-europe`} component={cargoeurope} exact />
          <Route path={`${process.env.PUBLIC_URL}/servicedetails/air-cargo-facts`} component={aircargofacts} exact />
          <Route path={`${process.env.PUBLIC_URL}/aboutus`} component={Aboutusmain} exact />
          <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={Termsandocondition} exact />
          <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={Privacypolicy} exact />
          <Route path={`${process.env.PUBLIC_URL}/cookies-policy`} component={Cookies} exact />
          <Route path={`${process.env.PUBLIC_URL}/services`} component={services} exact />
          <Route path={`${process.env.PUBLIC_URL}/packing-carting`} component={packingcrating} exact />
          <Route path={`${process.env.PUBLIC_URL}/custom-clerance`} component={customclerance} exact />
          <Route path={`${process.env.PUBLIC_URL}/domestic-trucking`} component={domestictrucking} exact />
          <Route path={`${process.env.PUBLIC_URL}/inspection`} component={inspections} exact />
          <Route path={`${process.env.PUBLIC_URL}/certificate`} component={certification} exact />
          <Route path={`${process.env.PUBLIC_URL}/contact`} component={contactus} exact />
          <Route path={`${process.env.PUBLIC_URL}/lets-collaborate`} component={letscollaborate} exact />
          <Route path={`${process.env.PUBLIC_URL}/place-your-bid`} component={placeyourbid} exact />
          <Route path={`${process.env.PUBLIC_URL}/get-quote`} component={getquote} exact />







        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
