import { API_URL } from '../Constants';
import axios from 'axios';

const aboutUsApi = ()=>{
    console.log(API_URL)
    return new Promise((resolve,reject)=>{
        axios.get(API_URL+'/api/about-us?populate=%2A')
        // axios.get('https://www.chartershare.org/backend/api/about-us')
          .then(function (response) {
            console.log(response);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
            reject(error.response);
          })
    });
}
export const submitContact = (body)=>{
    console.log(API_URL)
    return new Promise((resolve,reject)=>{
        axios.post(API_URL+"/api/ctas", body, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then(function (response) {
            console.log(response);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
            reject(error.response);
          })
    });
}
export default aboutUsApi;