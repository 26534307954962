import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import Header from '../../section/header/Header'

// import HeaderImage from "../../../src/components/images/Images";
function domestictrucking() {

    return (
        <>
            {/* <HeaderImage/> */}
            < Header/>
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Domestic Trucking</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                                <img src='https://i.postimg.cc/jjHT1J3S/Domestic-Trucking.jpg'></img>
                            </div>
                        </div>

                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Domestic Trucking</h3>
                                <p>
                                We can not only provide you with Van loads but offer Flatbeds and other specialized equipment which require Hazmat License. With access to several thousandoperators in the market we can ensure that you will always have a trailer to load on short notice. We also further offer track & trace services for a smooth cross border transaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default domestictrucking
