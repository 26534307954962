import React from 'react'
import FooterOne from '../../section/footer/FooterOne'
import Header from '../../section/header/Header'
function contactus() {
    return (
        <div>
            <Header />

            <div className='contactus'>
                <div className='banner-section'>
                    <h3>Contact Us</h3>
                </div>
                <div className='container'>

                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-12 mt-5'>
                            <div className='contanct-img'>
                               <img src='https://i.postimg.cc/T1R1XfVm/ANC4.png'></img>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='formbox mt-5'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-6 col-sm-12 col-md-12'>
                                            <div class="form-group">
                                                <label for="" className='formlabel'>First Name</label>
                                                <input type="text" class="form-control forminput" id="" aria-describedby="" placeholder="Full Name" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-12 col-md-12'>
                                            <div class="form-group">
                                                <label for="" className='formlabel'>Last Name</label>
                                                <input type="text" class="form-control forminput" id="" aria-describedby="" placeholder="Last Name" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-12 col-md-12'>
                                            <div class="form-group">
                                                <label for="" className='formlabel'>Email Id</label>
                                                <input type="email" class="form-control forminput" id="" aria-describedby="" placeholder="Email Id" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-12 col-md-12'>
                                            <div class="form-group">
                                                <label for="" className='formlabel'>Mobile No.</label>
                                                <input type="number" class="form-control forminput" id="" aria-describedby="" placeholder="Mobile No." />
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-sm-12 col-md-12'>
                                            <div class="form-group">
                                                <label for="" className='formlabel'>Message</label>
                                                <textarea class="form-control forminput" id="" rows="3">Message</textarea>
                                            </div>
                                        </div>
                                    </div>


                                    <button type="submit" class="btn btn-primary mt-3 formbtn">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='postbox'>
                                <div className='post-data'>
                                    <div className='post-image'>
                                        <img src='https://i.postimg.cc/MGcJN5J5/phone.png'></img>
                                    </div>
                                    <div className='post-detail'>
                                        <a href='tel:+91 (123) 456-7890'>+91 (123) 456-7890</a>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='postbox'>
                                <div className='post-data'>
                                    <div className='post-image'>
                                    <img src='https://i.postimg.cc/1z1K1RLd/location.png'></img>
                                    </div>
                                  
                                    <div className='post-detail'>
                                        <a href='tel:+91 (123) 456-7890'>+91 (123) 456-7890</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='postbox'>
                                <div className='post-data'>
                                    <div className='post-image'>
                                    <img src='https://i.postimg.cc/VLMRGbht/gmail.png'></img>
                                    </div>
                                
                                    <div className='post-detail'>
                                        <a href='mailto: demo@gmail.com'>demo@gmail.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </div>
    )
}

export default contactus
