import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";

// import HeaderImage from "../../../src/components/images/Images";
function remotedestination() {

    return (
        <>
            {/* <HeaderImage/> */}
            <BannerOne />

            <div className='detail-page'>
            <div className='banner-section'>
                    <h3>Remote destinations</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='detail-data'>
                                <div className='detail-image'>
                                    <img src='https://i.postimg.cc/QtGj4qf5/bannerback.jpg'>

                                    </img>
                                </div>
                                <div className='detail-content'>
                                    <h5>
                                        When scheduled flights, trucks, or maritime freight cannot easily reach or service remote or small destinations, or takes too long, chartering an aircraft is frequently the best option to transport goods.
                                    </h5>
                                    <p>
                                        Providing air charter services to hard-to-reach locations
                                    </p>
                                
                                    <div className='destination-data'>
                                        <h3>Natural Disaster Zone</h3>
                                        <p>
                                            Transporting essential medical items and delivering humanitarian aid and relief supplies.
                                        </p>
                                    </div>

                                    <div className='destination-data'>
                                        <h3>Under-serviced airports</h3>
                                        <p>
                                            Facilitate the safe landing of aircraft on unpaved or short runways, or airports not equipped to receive scheduled freight services, or with poor local infrastructure.
                                        </p>
                                    </div>
                                    <div className='destination-data'>
                                        <h3>Hazardous environments</h3>
                                        <p>
                                            Transporting military equipment, food, and essential medical supplies, and dangerous goods to areas of political disturbance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='details-left'>
                                <div className='details-left-data'>
                                    <div className='choose-heading'>
                                        <h4>
                                            Why choose CharterSync for your remote location charters?

                                        </h4>
                                    </div>

                                    <div className='chooseus  mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-headset'></i>
                                                    <h4>Expert team</h4>
                                                    <p>
                                                    Our team of charter experts are available to you around the clock to assist you at every step of the way, with any questions on delivering to remote locations you may have.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-globe'></i>
                                                    <h4>Going global</h4>
                                                    <p>
                                                    We work with airlines across the globe to ensure that no matter where you are, or where your cargo needs to go, we have the expertise and knowledge to assist you.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row2// */}
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Access to more airports</h4>
                                                    <p>
                                                    We have the ability to reach under-serviced locations, enabling you to deliver your cargo to its final destination, no matter where it needs to be.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Aircraft choice</h4>
                                                    <p>
                                                    With thousands of aircraft available to us, we’re sure we’ll always secure the right one for you.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default remotedestination
