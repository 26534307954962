import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";

// import HeaderImage from "../../../src/components/images/Images";
function heavyandoversized() {

    return (
        <>
            {/* <HeaderImage/> */}
            <BannerOne />

            <div className='detail-page'>
            <div className='banner-section'>
                    <h3>Heavy and oversized cargo</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='detail-data'>
                                <div className='detail-image'>
                                    <img src='https://i.postimg.cc/QtGj4qf5/bannerback.jpg'>

                                    </img>
                                </div>
                                <div className='detail-content'>
                                    <h5>
                                        Let us lighten the load – Meet your deadlines with CharterSync’s air cargo charter solutions for heavy and outsized cargo, offering you peace of mind that your cargo shipment will be delivered safely, and on time. Our specialist, expert team is on hand 24/7 to help you throughout your journey, ensuring you have the logistical support to move heavy cargo safely and efficiently.
                                    </h5>
                                </div>
                                <div className='detail-content'>
                                    <h3>Strategic thinking</h3>
                                    <p>Outsized cargo requires detailed planning. CharterSync provides a wide range of air charter solutions for the transportation of heavy cargo and oversized items, such as aircraft engines, vehicles, oil and gas equipment and dangerous goods.</p>
                                    <div className='detail-list'>
                                        <p>From Antonov AN-124s to Boeing 747Fs, we offer a range of aircraft services for heavy and oversized cargo, such as:</p>
                                        <ul>
                                            <li>
                                                Rear-loading freighters


                                            </li>
                                            <li>
                                                Nose-loading freighters


                                            </li>
                                            <li>
                                                Ramp-loading aircraft

                                            </li>
                                            <li>
                                                Aircraft with onboard cranes

                                            </li>
                                        </ul>
                                    </div>
                                    <div className='detail-list mt-3'>
                                        <p>Suitability put first</p>
                                        <p>
                                            Your account manager will be on hand to assist you with load planning, ensuring the most suitable aircraft is selected, recommend packaging materials, and collaborate with you on a detailed plan on how the cargo will load into the aircraft.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='details-left'>
                                <div className='details-left-data'>
                                    <div className='choose-heading'>
                                        <h4>
                                            Why choose CharterSync for your heavy and oversized air cargo charters?

                                        </h4>
                                    </div>

                                    <div className='chooseus  mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-users'></i>
                                                    <h4>24/7 support</h4>
                                                    <p>
                                                        Collaborate with our expert team, who are always on hand to provide end-to-end support of your time-critical shipments
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-airport'></i>
                                                    <h4>Cost efficiency</h4>
                                                    <p>
                                                        Our team understand the pressures of time-critical shipments and works with you to ensure you always receive a cost-effective solution for your needs
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row2// */}
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Going global</h4>
                                                    <p>
                                                        We work with airlines across the globe to ensure that no matter where you are, or where your cargo needs to go, we have the power to assist you.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                <i className="fas fa-time"></i> 
                                          <h4>TAircraft choice</h4>
                                                    <p>
                                                        With thousands of aircraft available at our fingertips, we’re sure we’ll always secure the right one for your charter
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row3 faq// */}


                                        <div className='rown mt-5'>
                                            <div className='col-lg-12 col-sm-12 col-md-12'>
                                                <div className="accordion faq-main" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Which aircraft can carry the most cargo?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                The Antonov An-225 Mriya currently holds the title of the world’s largest cargo aircraft. The Ukrainian cargo aircraft is powered by six turbofan engines and has 16 pairs of landing gear wheels.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            What is classed as heavy or outsized cargo?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                Items surpassing weight and/or dimension restrictions are referred to as heavy cargo. Individual cargo items that are too large or heavy to fit on a pallet or in the lower compartments are referred to as outsized cargo.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default heavyandoversized
