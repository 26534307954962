
const BannerOneData = [

// {
//       text:'Home',
//       to:'/'
//   },
//   {
//       text:'Services',
//       to:'#service'
//   },

{
    text:'Let’s Collaborate',
    to:'/lets-collaborate'
},  
  {
      text:'Place your Bid',
      to:'place-your-bid'
  },
 
 
 
  {
    text:'Get a Quote',
    to:'/get-quote'
}, 
{
    text:'About Us',
    to:'aboutus'
},
 
 {
    text:'Contact Us',
    to:'/contact'
},


{
    text:'Login',
    to:'/app'
}, 
]

const  BannerTwoData =  [
    // {
    //     text:'Home',
    //     to:'/'
    // },
    // {
    //     text:'Services',
    //     to:'#service'
    // },
  
    {
        text:'Let’s Collaborat',
        to:'/lets-collaborate'
    }, 
    {
        text:'Place your Bid',
        to:'place-your-bid'
    },
    {
        text:'About Us',
        to:'aboutus'
    },
     
     {
        text:'Contact Us',
        to:'/contact'
    },
   
    
    {
        text:'Login',
        to:'/app'
    },    
    {
        text:'Get a Quote',
        to:'/get-quote'
    }, 
]

const SubMenu =  [
    {
        text:'Get A Quote',
        to:'/get-quote'
    },
    {
        text:'Login',
        to:'/app'
    },
]

const BannerTwoIcon = [
    {icon:'star-fill'},
    {icon:'star-fill'},
    {icon:'star-fill'},
    {icon:'star-fill'},
    {icon:'star-fill'}
]

const BannerThreeData = [
    {
        text:'Landing',
        to:'landing',
        subMenu: [
            {
                text:'Landing Page- v1',
                to:'/',
            },{
                text:'Landing Page- v2',
                to:'/indextwo',
            },{
                text:'Landing Page- v3',
                to:'/indexthree'
            },
            {
                text:'Landing Page- v4',
                to:'/indexfour'
            },
            {
                text:'Landing Page- v5',
                to:'/indexfive'
            },
            {
                text:'Landing Page- v6',
                to:'/indexsix'
            },
        ]
    },
    {
        text:'Pages',
        to:'landing',
        subMenu: [
            {
                text:'Auth Pages',
                to:'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    }, 
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    }, 
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-forgotpassword"
                    }
                ]
            },{
                text:'Error Pages',
                to:'error',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/error/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/error/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/error/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/error/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text:'Home',
        to:'#home'
    },
    {
        text:'Products',
        to:'#product'
    },
    {
        text:'Plugins',
        to:'#plugin'
    },
    {
        text:'Pricing',
        to:'#pricing'
    },
    {
        text:'Reviews',
        to:'#reviews'
    },
]
const BannerFourData = [
    {
        text:'Landing',
        to:'landing',
        subMenu: [
            {
                text:'Landing Page- v1',
                to:'/',
            },{
                text:'Landing Page- v2',
                to:'/indextwo',
            },{
                text:'Landing Page- v3',
                to:'/indexthree'
            },
            {
                text:'Landing Page- v4',
                to:'/indexfour'
            },
            {
                text:'Landing Page- v5',
                to:'/indexfive'
            },
            {
                text:'Landing Page- v6',
                to:'/indexsix'
            },
        ]
    },
    {
        text:'Pages',
        to:'landing',
        subMenu: [
            {
                text:'Auth Pages',
                to:'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    }, 
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    }, 
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-forgotpassword"
                    }
                ]
            },{
                text:'Error Pages',
                to:'error',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/error/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/error/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/error/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/error/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text:'Home',
        to:'#home'
    },
    {
        text:'Features',
        to:'#features'
    },
    {
        text:'Pricing',
        to:'#pricing'
    },
    {
        text:'Story',
        to:'#story'
    },
]

const BannerFiveData = [
    {
        text:'Landing',
        to:'landing',
        subMenu: [
            {
                text:'Landing Page- v1',
                to:'/',
            },{
                text:'Landing Page- v2',
                to:'/indextwo',
            },{
                text:'Landing Page- v3',
                to:'/indexthree'
            },
            {
                text:'Landing Page- v4',
                to:'/indexfour'
            },
            {
                text:'Landing Page- v5',
                to:'/indexfive'
            },
            {
                text:'Landing Page- v6',
                to:'/indexsix'
            },
        ]
    },
    {
        text:'Pages',
        to:'landing',
        subMenu: [
            {
                text:'Auth Pages',
                to:'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    }, 
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    }, 
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-forgotpassword"
                    }
                ]
            },{
                text:'Error Pages',
                to:'error',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/error/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/error/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/error/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/error/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text:'Home',
        to:'#home'
    },
    {
        text:'Services',
        to:'#service'
    },
    {
        text:'Packages',
        to:'#package'
    },
    {
        text:'Team',
        to:'#team'
    },
    {
        text:'Reviews',
        to:'#reviews'
    },
]
const BannerSixData = [
    {
        text:'Landing',
        to:'landing',
        subMenu: [
            {
                text:'Landing Page- v1',
                to:'/',
            },{
                text:'Landing Page- v2',
                to:'/indextwo',
            },{
                text:'Landing Page- v3',
                to:'/indexthree'
            },
            {
                text:'Landing Page- v4',
                to:'/indexfour'
            },
            {
                text:'Landing Page- v5',
                to:'/indexfive'
            },
            {
                text:'Landing Page- v6',
                to:'/indexsix'
            },
        ]
    },
    {
        text:'Pages',
        to:'landing',
        subMenu: [
            {
                text:'Auth Pages',
                to:'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    }, 
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    }, 
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-forgotpassword"
                    }
                ]
            },{
                text:'Error Pages',
                to:'error',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/error/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/error/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/error/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/error/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text:'Home',
        to:'#home'
    },
    {
        text:'Features',
        to:'#feature'
    },
    {
        text:'Previews',
        to:'#previews'
    },
    {
        text:'Reviews',
        to:'#reviews'
    },
  
]
const BannerSevenData = [
    // {
    //     text:'About Us',
    //     to:'aboutus'
    // },
    // {
    //     text:'Contact',
    //     to:'/contact'
    // },
   
    
    // {
    //     text:'Login',
    //     to:'https://www.chartershare.org/app/'
    // },    
  
     
]


export {BannerOneData, BannerTwoData, BannerThreeData, BannerFourData, BannerFiveData, BannerSixData, BannerTwoIcon, BannerSevenData}
