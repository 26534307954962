import React from 'react'
import chosimageone from '../../src/assets/images/choseone.png'
import chosimagetwo from '../../src/assets/images/chosetow.png'
import chosimagethree from '../../src/assets/images/chosetthree.png'
import chosimagefour from '../../src/assets/images/chosefour.png'

function Chooseus() {
    return (
        <div className='chose-sec'>
            <div className='container'>

                <div className="justify-content-lg-center text-lg-center row"><div className="col-md-10 col-lg-6"><div className="section-head "><h2 className="title fw-bold">How It Works</h2><p class="fs-15px">We facilitate systematic collaboration to manage international air freight spend, achieve on-time delivery for urgent cargo and sustainability goals by reducing GHG emissions.</p></div></div></div>
                <div className='row'>
                    <div className='col-lg-3 col-sm-12 col-md-6'>
                        <div className='chose-box'>
                            <div className='chos-image'>
                            <img className="" src={chosimageone} alt="" />
                            </div>
                            <div className='chos-text mt-3'>
                                <h4>Charter Initiated</h4>
                            </div>
                            <div className='chos-para mt-3'>
                                <p>
                                Shipper books the charter flight for  shipping their urgent cargo
                                </p>
                            </div>
                        </div>
                    </div>
                
                    <div className='col-lg-3 col-sm-12 col-md-6'>
                        <div className='chose-box'>
                            <div className='chos-image'>
                            <img className="" src={chosimagetwo} alt="" />
                            </div>
                            <div className='chos-text mt-3'>
                                <h4>Charter Collaboration</h4>
                            </div>
                            <div className='chos-para mt-3'>
                                <p>
                                Shipper submits a collaboration request to share ride.
                                </p>
                            </div>
                        </div>
                    </div>
                

                    <div className='col-lg-3 col-sm-12 col-md-6'>
                        <div className='chose-box'>
                            <div className='chos-image'>
                            <img className="" src={chosimagethree} alt="" />
                            </div>
                            <div className='chos-text mt-3'>
                                <h4>Charter Execution</h4>
                            </div>
                            <div className='chos-para mt-3'>
                                <p>
                                Nominated Charter Broker / Freight Forwarder executes shipping. 
                                </p>
                            </div>
                        </div>
                    </div>
              
                    <div className='col-lg-3 col-sm-12 col-md-6'>
                        <div className='chose-box'>
                            <div className='chos-image'>
                            <img className="" src={chosimagefour} alt="" />
                            </div>
                            <div className='chos-text mt-3'>
                                <h4>Charter Repositioning</h4>
                            </div>
                            <div className='chos-para mt-3'>
                                <p>
                                Shippers can book charter flight to new route from destination airport at special rates.
                                </p>
                            </div>
                        </div>
                    </div>
              
                </div>
            </div>
        </div>
    )
}

export default Chooseus
