import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import Header from '../../section/header/Header'
import letscolab from '../../../src/assets/images/letscolaborate.png'
import { Link } from 'react-scroll';

// import HeaderImage from "../../../src/components/images/Images";
function letscollaborate() {

    return (
        <>
            {/* <HeaderImage/> */}
            <Header />
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Let’s Collaborate</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                                <img src={letscolab}></img>
                            </div>
                        </div>


                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Let’s Collaborate</h3>
                                <p>
                                    Login to achieve synergies by notifying shippers with available space on your planned charter flight so they can collaborate to share the ride.
                                </p>
                                <a href='/app/' className='btn-login'> <img
                                 src='https://i.postimg.cc/9XvPm0md/user-2.png' className='logimg'></img>Login  </a>
                                
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default letscollaborate
