import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";

// import HeaderImage from "../../../src/components/images/Images";
function timecritical() {

    return (
        <>
            {/* <HeaderImage/> */}
            <BannerOne />

            <div className='detail-page'>
            <div className='banner-section'>
                    <h3>Time-critical</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 '>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='detail-data'>
                                <div className='detail-image'>
                                    <img src='https://i.postimg.cc/QtGj4qf5/bannerback.jpg'>

                                    </img>
                                </div>
                                <div className='detail-content'>
                                    <h5>
                                    When time is of the essence, our devoted, knowledgeable team is here to offer you an easy, efficient, and effective, charter solution.
                                    </h5>
                                    <div className='destination-data'>
                                        <h3>Time-critical cargo charters</h3>
                                        <p>
                                        We are aware of the effects of abrupt or unforeseen delays, such as those brought on by AOG situations, delays to manufacturing caused by a missing part, or a time-sensitive pharmaceutical delivery. Time-critical charter flights are a more effective solution when the cost of the delay is rising.
                                        </p>
                                    </div>
                                    <div className='destination-data'>
                                        <h3>Time-critical quotes</h3>
                                        <p>
                                        We offer a wide selection of aircraft options and prices in a matter of minutes thanks to our cutting-edge technology, ensuring that your urgent cargo shipment will take off as soon as possible.
                                        </p>
                                    </div>
                                 
                                    <div className='destination-data'>
                                        <h3>Time-critical logistics</h3>
                                        <p>
                                        The CharterSync technology allows you to track your flight in real-time during your time-critical journey. From the moment your cargo starts loading on the aircraft until it is offloaded at the final destination, you are continuously updated and involved throughout the entire process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='details-left'>
                                <div className='details-left-data'>
                                    <div className='choose-heading'>
                                        <h4>
                                        When every minute counts, you can count on us

                                        </h4>
                                    </div>

                                    <div className='chooseus  mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-headset'></i>
                                                    <h4>Rapid quotes</h4>
                                                    <p>
                                                    Our state-of-the-art technology, combined with our knowledgeable team, means you’ll have a wide range of charter options delivered to you within minutes of your request.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-globe'></i>
                                                    <h4>Online contracting</h4>
                                                    <p>
                                                    Secure online contracting for a smoother, more efficient agreement process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row2// */}
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Cost efficiency</h4>
                                                    <p>
                                                    Our team understand the pressures of time-critical shipments and works with you to ensure you always receive a cost-effective solution for your needs.


                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Worldwide availability</h4>
                                                    <p>
                                                    No matter where your shipment needs are located or need to get to, our expert team are well-trained on a global scale.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                     
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default timecritical
