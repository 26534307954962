import React from 'react'

const User = (props) => {
  return (
    <div>
    <div className={["user", props.className ? props.className : null].join(' ')}>
        <div className="img mx-auto">
            <img className=" sm" src={props.src} alt="img" />
        </div>
        
    </div>
    <div className="info w-100 text-center py-3">
            <h6 className="name">{props.name}</h6>
            <div className="role">{props.role}</div>
            <div className="role">{props.email}</div>
        </div>
        </div>
  )
}

export default User
