import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import Header from '../../section/header/Header'
import letscolab from '../../../src/assets/images/place.jpg'

// import HeaderImage from "../../../src/components/images/Images";
function placeyourbid() {

    return (
        <>
            {/* <HeaderImage/> */}
           <Header/>
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Place Your Bid</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                               <img src={letscolab}></img>
                            </div>
                        </div>

                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Place Your Bid</h3>
                                <p>
                                Login to Unlock cost & carbon emission savings by requesting quote or placing a competitive bid for available space on planned charter flight.
                                </p>
                                <a href='/app' className='btn-login'> <img
                                 src='https://i.postimg.cc/9XvPm0md/user-2.png' className='logimg'></img>Login  </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default placeyourbid
