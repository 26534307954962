import { API_URL } from '../../Constants'
import React, { useState, useEffect } from 'react'
import HeaderImg from '../../images/header/main_image.jpg'
import LogoDrak2x from '../../images/logo-dark2x.png'
import LogoLight2x from '../../images/logo2x.png'
import CSLogo from '../../images/cs_new_logo_trans2.png'
import Menu from '../../layout/menu/Menu'
import MobileMenu from '../../layout/menu/MobileMenu'
import { Logo } from "../../components/logo/Logo"
import { Col, Container, Row } from "reactstrap"
import { Header, HeaderWrap, HeaderContent, HeaderMain } from "../../layout/header/Header"
import { Link } from "../../components/button/Button"
import { HeaderCaption, HeaderText, HeaderTitle } from '../../components/headerCaption/HeaderCaption'
import { HeaderImage } from '../../components/images/Images'
import { BannerOneData, BannerSevenData, BannerTwoData } from './BannerData'
import { homeApi } from '../../api/HomeApi'
import { BgImage } from '../../layout/ovm/Ovm'

const BannerOne = (props) => {
    const [toggle, setToggle] = useState(false);
    const [offset, setOffset] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [image, setImage] = useState("");

    useEffect(() => {
        //  applyApidata();
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
        window.scrollTo(0, 0);
        viewChange();
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener('resize', viewChange);
        };
    }, []);


    useEffect(() => {
        applyApidata();

    }, [image]);

    const applyApidata = () => {
        const result = homeApi();

        result.then(success => {
            setTitle(success.data.attributes.title);
            setDesc(success.data.attributes.description);
            const urlImage = API_URL + success.data.attributes.image.data.attributes.url;
            setImage(urlImage);
            console.log(urlImage);
        }, error => {

        });
    }
    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    };


    return (
        <Header className={props.className && props.className} id={props.id && props.id}>
            {/* <HeaderMain className={`header-main header-main-s1  on-dark  ${offset > 0 ? "has-fixed" : ""}`}> */}
            <HeaderMain className={`header-main header-main-s1  on-dark`}>
                <Container className="header-container">
                    <HeaderWrap>
                        <div className="header-logo">
                            {/* <Logo to="/"
                                dark={CSLogo}
                                light={CSLogo}
                            /> */}

                            <Link to="/">  <img src='https://i.postimg.cc/g0cnqhCm/Charter-Share-Logo-removebg-preview.png'></img></Link>
                          
                        </div>
                        <div className="header-toggle" onClick={() => setToggle(!toggle)}>
                            <button className={`menu-toggler ${toggle === true ? "active" : ""}`}>
                                <em className="menu-on icon ni ni-menu"></em>
                                <em className="menu-off icon ni ni-cross"></em>
                            </button>
                        </div>
                        <div className='rightmenu'>
                        <nav className={`topheader  `}>
                            {!mobileView ? <Menu className="ml-lg-auto justify-end" data={BannerSevenData} /> : <MobileMenu data={BannerSevenData} />}
                        </nav>
                        <nav className={`header-menu menu ${toggle === true ? "active" : ""} ${mobileView ? "mobile-menu" : ""}`}>
                            {!mobileView ? <Menu className="ml-lg-auto" data={BannerOneData} /> : <MobileMenu data={BannerTwoData} />}

                        </nav>
                        </div>
                      
                        {toggle && window.innerWidth < 992 && <div className="header-overlay" onClick={() => setToggle(!toggle)}></div>}
                    </HeaderWrap>
                </Container>
            </HeaderMain>

           
            {/* <HeaderContent className="mr-7 pr-4">

                <div className="header-toggle" onClick={() => setToggle(!toggle)}>
                    <button className={`menu-toggler ${toggle === true ? "active" : ""}`} style={{ border: '1px solid black', background: 'red' }}>
                        <em className="menu-on icon ni ni-menu"></em>
                        <em className="menu-off icon ni ni-cross"></em>
                    </button>
                </div>
             
                <nav className={`header-menu menu ${toggle === true ? "active" : ""} ${mobileView ? "mobile-menu" : ""}`}>
                    {!mobileView ? <Menu className="ml-lg-auto" data={BannerSevenData} /> : <MobileMenu data={BannerSevenData} />}
                </nav>
                {toggle && window.innerWidth < 992 && <div className="header-overlay" onClick={() => setToggle(!toggle)}></div>}
            </HeaderContent> */}
            <HeaderContent className="my-auto py-5">
                <div className="container">
                    <Row className="row justify-content-center text-center text-start">
                        <Col lg="7" md="10">
                            <HeaderCaption>
                            <HeaderTitle>{title}</HeaderTitle>
                            <HeaderText>
                                <p>{desc}</p>
                            </HeaderText>
                        </HeaderCaption> 
                        </Col>
                    </Row>
                </div>
            </HeaderContent>
            {/* <HeaderImage className="mb-n5 mb-sm-n6 mb-md-n7">
                <Container>
                    <Row className='justify-content-center'>
                        <Col xl="10">
                             <div className="card overflow-hidden p-2 bg-light">
                            <img src={image} alt="" />
                        </div> 
                        </Col>
                    </Row>
                </Container>
            </HeaderImage> */}
        </Header>
    )
}


export default BannerOne
