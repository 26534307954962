import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../section/banner/BannerOne";

// import HeaderImage from "../../../src/components/images/Images";
function services() {

    return (
        <>
            {/* <HeaderImage/> */}
            <BannerOne />
            <section>
                <div className='banner-section'>
                    <h3>Our Service</h3>
                </div>
            </section>
            <FooterOne />
        </>
    )
}

export default services
