    import React from 'react'
    import Footer from '../../layout/footer/Footer'
    import FooterOne from '../../section/footer/FooterOne'
    import BannerOne from "../../../src/section/banner/BannerOne";

    // import HeaderImage from "../../../src/components/images/Images";
    function aircargofacts() {

        return (
            <>
                {/* <HeaderImage/> */}
                <BannerOne />
                <div className='blog-detail'>
                <div className='banner-section'>
                    <h3>Blogs</h3>
                </div>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-lg-7 col-sm-12 col-md-6 '>
                                <div className='blog-data'>
                                    <div className='blog-image'>
                                        <img src='https://i.postimg.cc/fRrLKhBw/bannerback.jpg'></img>
                                    </div>
                                    <div className='blog-content'>
                                        <p>

                                            Because it enables speedy and effective global shipping of commodities, air cargo charters are a crucial part of the global economy, enabling companies to ship goods around the world at speed. Here are some interesting facts and statistics that showcase the significance of air cargo in today’s world
                                        </p>
                                    
                                        <div className='blogs-list'>
                                            <p>
                                                It provides an opportunity to get insights from professionals in the urgent air cargo charter market with 10+ years of experience:
                                            </p>
                                            <ul>
                                                <li>
                                                The global air cargo market is expected to grow by 19.52 million tonnes during 2023-2027, accelerating at a CAGR of 5.32% during the forecast period. A report conducted by The Analyst offers an up-to-date analysis regarding the current global market scenario, the latest trends and drivers, and the overall market environment. The market is driven by the increase in e-commerce sales to boost the air cargo market, growing demand from APAC, and rising demand from just-in-time manufacturers.

                                                </li>
                                                <li>
                                                In 2023, the Asia Pacific region accounted for the largest share of the global air cargo market, with China being the largest market in the region.

                                                </li>
                                                <li>
                                                The e-commerce industry has been a major driver of air cargo growth in recent years. In 2019, the volume of e-commerce air cargo increased by 14% compared to the previous year
                                                </li>
                                                <li>
                                                In recent years, healthcare and pharmaceutical products have been the fastest growing air cargo sectors, with demand for these two industries expected to further increase in the next few years.
                                                </li>
                                                <li>
                                                The Cargo Facts Consulting firm forecasts that the global freighter fleet will rise from 1,782 to 2,920 cargo aircraft from 2019 to 2039, and is expected to hit 2000 by 2028, with the majority of these aircraft being narrowbody freighters
                                                </li>
                                                <li>
                                                The top three global air cargo hubs are Memphis International Airport (MEM), Hong Kong International Airport (HKG) and Shanghai Pudong International Airport (PVG), handling a combined 134 million metric tons of cargo in 2021 combined.                                            </li>
                                                <li>
                                                Air cargo’s share of global trade has been increasing in recent years and is forecasted to continue growing year-on-year for the foreseeable future. In 2019, air cargo accounted for around 35% of global trade by value.
                                                </li>
                                                <li>
                                                The use of digital technologies, such as big data, blockchain and the internet of things is increasing in the air cargo and aviation industries, with these technologies mainly being used to improve supply chain visibility, efficiency and security.

                                                </li>
                                                <p>
                                                These figures and statistics showcase how vital the air cargo industry is becoming to the modern world economy. With the e-commerce industry, healthcare products, and technological advancements driving the market and the share of global trade value steadily increasing, air cargo is set to play an even more significant role in the future.
                                                </p>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-sm-12 col-md-6 left-data'>
                                <div className='blogs-form-data'>
                                    <h3>
                                        Join our mailing list.
                                    </h3>
                                    <div className='blog-form'>
                                        <form>
                                            <div className="form-group">
                                                <label for="" className='formlabel'>Full Name</label>
                                                <input type="text" class="form-control forminput" id="" aria-describedby="" placeholder="Full Name" />

                                            </div>
                                            <div className="form-group">
                                                <label for="" className='formlabel'>Last Name</label>
                                                <input type="text" class="form-control forminput" id="" aria-describedby="" placeholder="Last Name" />

                                            </div>
                                            <div className="form-group">
                                                <label for="" className='formlabel'>Email Id</label>
                                                <input type="email" class="form-control forminput" id="" aria-describedby="" placeholder="Email Id" />

                                            </div>
                                            <div className="form-group">
                                                <label for="" className='formlabel'>Company Name</label>
                                                <input type="text" class="form-control forminput" id="" aria-describedby="" placeholder="Company Name" />

                                            </div>
                                            <button type="submit" class="btn btn-primary formbtn">Submit</button>
                                        </form>
                                    </div>
                                </div>

                                <div className='latest-blogs mt-5'>
                                    <div className='main-heading'>
                                        <h3>
                                            Latest Blog Post
                                        </h3>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-lg-4 col-md-12 col-sm-12'>

                                            <a href='/servicedetails/dangerousgoods'>
                                                <div className='latest-image'>
                                                    <img src='https://i.postimg.cc/xj48p6SV/blogs.png'></img>
                                                </div>

                                            </a>

                                        </div>
                                        <div className='col-lg-8 col-sm-12 col-md-12'>
                                            <div className='latest-content'>
                                                <p>AVEM AERO is holding a free online event to help brokers and operators cooperate effectively in the “go now” air cargo charter market.</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row mt-3'>
                                        <div className='col-lg-4 col-md-12 col-sm-12'>

                                            <a href='/servicedetails/dangerousgoods'>
                                                <div className='latest-image'>
                                                    <img src='https://i.postimg.cc/xj48p6SV/blogs.png'></img>
                                                </div>

                                            </a>

                                        </div>
                                        <div className='col-lg-8 col-sm-12 col-md-12'>
                                            <div className='latest-content'>
                                                <p>
                                                    Air Cargo Europe, the world’s leading trade fair for the global air cargo industry, recently concluded its highly anticipated event in Munich.</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>




                        </div>
                    </div>
                </div>
                <FooterOne />
            </>
        )
    }

    export default aircargofacts
