import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../section/banner/BannerOne";
import Header from '../../section/header/Header'
// import HeaderImage from "../../../src/components/images/Images";
function Aboutusmain() {

    return (
        <>
            {/* <HeaderImage/> */}
            <Header />
            <section className='aboutsec'>
                <div className='banner-section'>
                    <h3>About Us</h3>
                </div>
                <div className='container '>
                    <div className='abt-head text-center mt-5'>
                        <h1>About Charter Share</h1>
                    </div>
                    {/* <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-6 mt-5'>
                            <div className='aboutimage'>
                                <img src='https://i.postimg.cc/jSX43CdT/anoutsta.jpg'></img>
                            </div>
                        </div>

                        <div className='col-lg-6 col-sm-12 col-md-6 '>
                            <div className='aboutcontent'>
                                <h4>About Us</h4>
                                <p>
                                Global Supply Chain professional and CharterShare founder , Vinit Pednekar is an expert in global logistics, with more than 15 years of experience.  
                                </p>
                                <p>
                                He brings his unique and exceptional experience as a multicultural supply chain leader in the energy industry, coupled with his extraordinary abilities in integrating logistics and innovation to improve processes and operations. Vinit received a B.S.
                                </p>
                                <p>
                                degree in Chemical Engineering and an M.B.A. in International Business with a specialization in Supply Chain from India. He is a Fellow Chartered member of Center of Logistics and Transport and active member of Association for Supply Chain Management in the United States.
                                </p>
                            </div>
                        </div>
                    </div> */}
                </div>


                {/* <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='aboutcontent'>
                                <h4>Why Charter Share</h4>
                                <p>Business dynamics have changed because of the coronavirus pandemic. Companies that were once striving for higher market share are now more concerned about their margins. Most of these multinational companies have mature supply chains, and due to digital transformation programs, they have already managed to lower their spend and improve service quality. This means that finding new internal cost saving opportunities will be difficult. In the current environment, we need to explore and leverage external synergies that exist between our strategic partners. This platform will help companies, freight forwarders and charter brokers leverage these synergies by facilitating cargo consolidation while chartering a flight.</p>

                            </div>
                        </div>

                        <div className='col-lg-6 col-sm-12 col-md-6 mt-5'>
                            <div className='aboutimage abouttwoimg'>
                                <img src='https://i.postimg.cc/1zQrNNsZ/IMG-0499.jpg'></img>

                            </div>
                        </div>

                    </div>
                </div> */}

                {/* /	Supply Chain Disruptions: A Timeline// */}
                <div className='container'>
                    {/* <div className='row'>


                        <div className='col-lg-12 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Supply Chain Disruptions: A Timeline</h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            It all started with the pandemic, activity came to a standstill putting lot of pressure on Oil & Gas Prices, impact was reduction in our margins
                                        </li>
                                        <li>
                                            Towards end of 2020 we saw the container crisis because of which reliability dropped, 30-60% for ocean, 60-75% for air.
                                        </li>
                                        <li>
                                            In early 2021 we started facing labor and raw material shortages, leading to hike in the commodity price. More than 30% increase in the cost of Nickel, copper and steel.
                                        </li>
                                        <li>
                                            IN H2 of last year we had the omicron surge because of which plants and ports were closed, this led to shortage of electronic components and batteries from China which are used for manufacturing drilling tools.
                                        </li>
                                        <li>In recent past we witnessed something which not many would have imagined, due to Ukraine and Russia conflict we had some serious shipping problems. Many charter planes were destroyed or grounded and this created supply / demand constraints
                                        </li>
                                        <li>
                                            Even now we have strikes, trade barriers and lot of uncertainty.  As compared to sea freight stakes are high when we ship odd dimension cargo via Air, so today I’m mainly focusing on Air cargo Charters.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-sm-12 col-md-12 d-flex align-items-center justify-content-center'>
                            <div className='aboutimage'>
                                <img src='https://i.postimg.cc/W3ddMYZs/whycharte.png'></img>
                            </div>
                        </div>
                    </div> */}

                    <div className='row abt-one'>


                        <div className='col-lg-6 col-sm-12 col-md-12 justify-content-center d-flex align-items-center'>
                            <div className='aboutcontent'>
                                {/* <h4>Supply Chain Disruptions: A Timeline</h4> */}
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            As Supply Chain Disruptions continue resulting in decreased operating margins while increasing Cost of Service and Delivery Lead-time
                                        </li>
                                        {/* <li>
                                            The Air Charter market remains chaotic as Jet fuel prices are not falling in sync with crude.  Charter capacity will continue to be an issue for the next 5 years till new flights come into the market.Moreover, consistent underperformance of operated flights, 30% of the times cargo flights are not flying as scheduled so there is no guarantee that booked cargo will reach on time.
                                        </li> */}

                                    </ul>
                                </div>
                                <div className='aboutimage'>
                                    <img src='https://i.postimg.cc/W3ddMYZs/whycharte.png'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-6 col-md-12 d-flex align-items-center justify-content-center'>
                            <div className='aboutimage abt-img'>
                                <img src='https://i.postimg.cc/wMcm77Vy/abtimage.jpg'></img>
                            </div>
                        </div>
                    </div>

                </div>

                {/* //Air Charter market remains chaotic// */}
                <div className='container '>
                    {/* <div className='row'>
                        <div className='col-lg-12 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Air Charter market remains chaotic</h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            Don’t expect reduction in charter flight rates as we can see here that Jet fuel prices not falling in sync with crude and charter pricing is dependent on flight availability.
                                        </li>
                                        <li>
                                            Capacity will be an issue till new flights come in market and it will take 5-10 yrs more to revover.
                                        </li>
                                        <li>
                                            Capacity will still remain challenge going forward especially during Q4 as there is heavy demand already from Ecommerce players like Amazon.
                                        </li>
                                        <li>
                                            We can see consistent underperformance of operated flights, 30% of the times cargo flights are not flying as scheduled so there is no guarantee that even after paying high cost our cargo will reach on time. Airlines have suffered heavy losses since the pandemic so they don’t have any more capacity to absorb cost, , as quoted by Forbes Magazine’s many airlines are reporting reduction in their schedule which means more delays and need for chartering an aircraft.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-sm-12 col-md-12'>
                            <div className='aboutimage row'>
                                <div className='col-lg-6 col-sm-12 col-md-12'>
                                    <img src='https://i.postimg.cc/QN933ZsX/chartermarket.png'></img>
                                </div>

                                <div className='col-lg-6 col-sm-12 col-md-12'>
                                   
                                    <img src='https://i.postimg.cc/QN933ZsX/chartermarket.png'></img>

                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Air Charter market remains chaotic</h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            Don’t expect reduction in charter flight rates as we can see here that Jet fuel prices not falling in sync with crude and charter pricing is dependent on flight availability.
                                        </li>
                                        <li>
                                            Capacity will be an issue till new flights come in market and it will take 5-10 yrs more to revover.
                                        </li>
                                        <li>
                                            Capacity will still remain challenge going forward especially during Q4 as there is heavy demand already from Ecommerce players like Amazon.
                                        </li>
                                        <li>
                                            We can see consistent underperformance of operated flights, 30% of the times cargo flights are not flying as scheduled so there is no guarantee that even after paying high cost our cargo will reach on time. Airlines have suffered heavy losses since the pandemic so they don’t have any more capacity to absorb cost, , as quoted by Forbes Magazine’s many airlines are reporting reduction in their schedule which means more delays and need for chartering an aircraft.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutimage row'>
                                <div className='col-lg-6 col-sm-12 col-md-12'>
                                    <img src='https://i.postimg.cc/QN933ZsX/chartermarket.png'></img>
                                </div>

                                <div className='col-lg-6 col-sm-12 col-md-12'>

                                    <img src='https://i.postimg.cc/QN933ZsX/chartermarket.png'></img>

                                </div>
                            </div>
                        </div>
                    </div> */}



                    <div className='row  abt-one'>
                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutimage row'>
                                <div className='col-lg-12 col-sm-12 col-md-12'>
                                    <img src='https://i.postimg.cc/QN933ZsX/chartermarket.png'></img>
                                </div>

                                <div className='col-lg-12 col-sm-12 col-md-12'>

                                    <img src='https://i.postimg.cc/QN933ZsX/chartermarket.png'></img>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center'>
                            <div className='aboutcontent'>
                                {/* <h4>Air Charter market remains chaotic</h4> */}
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            The Air Charter market remains chaotic as Jet fuel prices are not falling in sync with crude.  Charter capacity will continue to be an issue for the next 5 years till new flights come into the market.Moreover, consisten¬¬¬¬t underperformance of operated flights, 30% of the times cargo flights are not flying as scheduled so there is no guarantee that booked cargo will reach on time.
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' container chrt-flt mt-5'>
                    <img src='https://i.postimg.cc/BZPTDBN3/charterflight.jpg'></img>
                </div>

                {/* //o	Challenges: Air Charters// */}
                {/* <div className='container '>
                    <div className='row'>
                        <div className='col-lg-12 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Challenges: Air Charters</h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            Chartering aircraft was not normal for us because general mode of transport is air freight, but because of  Longer LT and unreliability many OFS companies have seen unprecedented  increase in usage of charter flights..
                                        </li>
                                        <li>
                                            These are some common issue which we see while managing charter flights , some of these are internal whereas few are shipping industry complexities
                                        </li>
                                        <li>
                                            With mergers and acquisitions there are multiple teams and systems within a company managing shipments to similar destination
                                        </li>
                                        <li>
                                            Competition between Freight Forwarders / Brokers, they don’t actively find partners for us to share ride so we don’t have much Co-Loading
                                        </li>
                                        <li>
                                            Centralization, even strategic shipments are managed by people in low cost locations, they don’t necessarily have exposure or bandwidth to mange consolidation or route optimization
                                        </li>
                                        <li>
                                            Lastly , Global shippers like us don’t actively collaborate  Most of the times we get  inflated quotes because the operator will consider the dispositioning cost or cost to rest the crew till they find next load
                                        </li>
                                        <li>
                                            So the result is Missed opportunities to consolidate and empty ferries
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-sm-12 col-md-12'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/vmNNqD45/chartetwo.png'></img>
                            </div>
                        </div>
                    </div>
                </div> */}




                <div className='container '>
                    <div className='row abt-one'>
                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className=''>
                                {/* <h4>Challenges: Air Charters</h4> */}
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            Chartering aircraft is not normal as the general mode of transport is air freight, but because of Longer LT and unreliability, MNC’s/ shippers have seen unprecedented increase in usage of charter flights. Lack of visibility, skillset, competition amongst Freight Forwarders and inadequate planning results in empty ferries and missed ride sharing opportunity.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/vmNNqD45/chartetwo.png'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* //	Case for Change : Air Charters// */}
                {/* <div className='container '>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Case for Change : Air Charters</h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            Logistics/ Transport spend is an important cost line, on an average Cargo charters contribute up to 5% of the overall logistics spend which is easily up to $20M per annum
                                        </li>
                                        <li>
                                            Air Charter industry is growing and in Y2029 is expected to be more than $52B, if you see below numbers the number of charter flights operated have been increased, infact some of the FF are leasing aircrafts and operating busy lanes at premium cost
                                        </li>
                                        <li>
                                            When discussed with some of the industry experts from these companies , even being conservative there is more than 25% potential to Co-Load or avoid empty ferries                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/W4xnC1ZZ/image-2023-09-21-T10-39-22-548-Z.png'></img>
                            </div>
                        </div>
                    </div>
                </div> */}



                <div className='container '>
                    <div className='row abt-one'>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/W4xnC1ZZ/image-2023-09-21-T10-39-22-548-Z.png'></img>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center'>
                            <div className='aboutcontent'>

                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            Logistics spend is an important cost line for MNC’s, on an average Air charter contributes up to 5% of the overall logistics spend. Charter industry is growing rapidly, in Y2029 it’s expected to be more than $52B.
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className='container '>
                    <div className='row abt-one'>
                     
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center'>
                            <div className='aboutcontent'>

                                <div className='about-list'>
                                    <ul>
                                        <li>
                                    Business dynamics have changed, MNC’s are striving for higher market share are now concerned about their margins. With a mature supply chain finding internal cost saving opportunities for them is difficult. They need to explore external synergies that exist between their strategic partners and other shippers. CharterShare will help MNC’s, Freight Forwarders and Charter Brokers leverage such synergies by facilitating cargo consolidation while chartering a flight.
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage '>
                                <img src='static/media/letscolaborate.8ead5cb079ef94e84bd6.png'></img>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='container '>
                    <div className='row '>
                    <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage v-img'>
                                <img src='https://i.postimg.cc/ryCywgPs/19199474.jpg'></img>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center'>
                            <div className='aboutcontent'>

                                <div className='about-list'>
                                    <ul>
                                        <li>
                                        Multiple examples since the last few years where we have successfully managed cargo consolidation while managing air charters. This collaboration has helped lower logistics spend and GHG emissions.
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                      

                    </div>
                </div>

                <div className='container '>
                    <div className='row '>
                  
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center'>
                            <div className='aboutcontent'>

                                <div className='about-list'>
                                    <ul>
                                        <li>
                                        Vision is to find strategic partners who can be part of a consortium to consolidate cargo and optimize charter flight capacity at optimal cost.
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage v-img'>
                                <img src='https://i.postimg.cc/sxXBKsC1/5899209.jpg'></img>
                            </div>
                        </div>

                    </div>
                </div>

               
                {/* //	Leveraging Synergies  */}
                {/* <div className='container '>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/NfmG3JrP/masp.png'></img>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Leveraging Synergies </h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            These are few internal example to show how we managed to consolidate shipments internally using route optimization technique to reduce spend and manage on time delivery
                                        </li>
                                        <li>
                                            Example 1 is where we manage a milk run to collect and deliver shipment from various places to various destinations. We started from Brussels consolidating shipments in Europe managed stops in Canada and US on the way to POS to deliver critical items. In this example we avoided multiple charters, and managed to deliver everything using a single charter
                                        </li>
                                        <li>
                                            In the 2nd example instead of doing a charter flight all the way from Singapore to Caribbean , we managed 1st leg on regular A/f and leg 2 we consolidated  charter with all cargo available in Europe . By doing this we managed CHG savings + lower spend                                      </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* //		Leveraging external synergies  */}
                {/* <div className='container '>
                    <div className='row'>

                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Leveraging external synergies </h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                        Most of the times we get a feedback that charters are time sensitive and its difficult to co-load , but there are cases where we managed to collaborate with suppliers and other MNC’s
                                        </li>
                                        <li>
                                        In 1st examples instead of sending a charter half-filled we collaborated with our suppliers to share the ride , in this case we moved 62 MT in total whereas initially we only planned for 23MT.
                                        </li>
                                        <li>
                                        In 2nd example an OFS company did a charter to Guyana , we managed to fill that flight on its way back to avoid empty ferry , this was not only cost effective but we also managed to deliver on-time                              </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/8Pq0tHh8/leverginngtwi.png'></img>
                            </div>
                        </div>
                    </div>
                </div> */}


                {/*  Mission */}
                {/* <div className='container '>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-5'>
                            <div className='aboutimage '>
                                <img src='https://i.postimg.cc/bvc2kSfs/MISSION.png'></img>
                            </div>
                        </div>

                        <div className='col-lg-6 col-sm-12 col-md-12'>
                            <div className='aboutcontent'>
                                <h4>Logistics Collaboration </h4>
                                <div className='about-list'>
                                    <ul>
                                        <li>
                                            <strong>Mission</strong> actively find strategic partners who can be part of a consortium to consolidate cargo and optimize charter flight capacity at optimal cost
                                        </li>
                                        <li>
                                            <strong>Vission</strong>  is to find strategic partners who can be part of a consortium to consolidate cargo and optimize charter flight capacity at optimal cost. 
                                        </li>
                                        <li>
                                            We are approaching year-end rush, with less cargo flights we will again find ourself struggling to deliver time sensitive, OD cargo on time.                                 </li>
                                        <li>
                                            We have already collaborating with few OFS companies, if you to be part of this consortium to find charter sharing opportunities, please send us an enquiry
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  */}
            </section>
            <FooterOne />
        </>
    )
}

export default Aboutusmain
