import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import Header from '../../section/header/Header'

// import HeaderImage from "../../../src/components/images/Images";
function packingcrating() {

    return (
        <>
            {/* <HeaderImage/> */}
           <Header/>
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Packing & Crating</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                                <img src='https://i.postimg.cc/fbrk90kJ/Packing-Crating.jpg'></img>
                            </div>
                        </div>

                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Packing & Crating</h3>
                                <p>
                                Most of the shippers lack packing & crating expertise, Hazmat cargo requires special packaging and only certified DG personnel can provide Dangerous Goods Certificate. Our team of reliable charter brokers and freight forwarders are certified andtrained on the latest restrictions, ICAO and IATA regulations and technical instructions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default packingcrating
