import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";

// import HeaderImage from "../../../src/components/images/Images";
function dangerousgoods() {

    return (
        <>
            {/* <HeaderImage/> */}
            <BannerOne />

            <div className='detail-page'>
            <div className='banner-section'>
                    <h3>Dangerous goods</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='detail-data'>
                                <div className='detail-image'>
                                    <img src='https://i.postimg.cc/QtGj4qf5/bannerback.jpg'>

                                    </img>
                                </div>
                                <div className='detail-content'>
                                    <h5>
                                        Every year, over one and a half million dangerous goods are transported via air cargo charter, with that number steadily growing year-on-year. The shipment of dangerous and hazardous goods requires a specialist skillset and expert knowledge, and it’s vital to follow the latest regulations and shipping guidelines.
                                    </h5>
                                </div>
                                <div className='detail-content'>
                                    <h3>A unique set of challenges</h3>
                                    <p>The CharterSync team are trained and fully up-to-date on the latest restrictions, ICAO and IATA regulations and technical instructions.</p>
                                    <div className='detail-list'>
                                        <p>We’re experts in shipping dangerous goods via air cargo, including:</p>
                                        <ul>
                                            <li>
                                                Finding the most suitable aircraft for your cargo shipment

                                            </li>
                                            <li>
                                                Ensuring the correct aircraft is selected based on the parameters of your request

                                            </li>
                                            <li>
                                                Advising on the class of dangerous goods and the quantity  limits
                                            </li>
                                            <li>
                                                Assisting you with permits where applicable

                                            </li>
                                        </ul>
                                    </div>
                                    <div className='detail-list mt-3'>
                                        <p>Our areas of expertise include</p>
                                        <ul>
                                            <li>
                                                Oil & Gases


                                            </li>
                                            <li>
                                                Lithium batteries


                                            </li>
                                            <li>
                                                Flammables

                                            </li>
                                            <li>
                                                Assisting you with permits where applicable

                                            </li>
                                            <li>
                                                Toxic and infectious substances

                                            </li>
                                            <li>
                                                Radioactive materials

                                            </li>
                                            <li>
                                                Corrosive goods

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-md-6'>
                            <div className='details-left'>
                                <div className='details-left-data'>
                                    <div className='choose-heading'>
                                        <h4>
                                            Why choose CharterSync for your dangerous goods shipments

                                        </h4>
                                    </div>

                                    <div className='chooseus  mt-3'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-users'></i>
                                                    <h4>Expert team</h4>
                                                    <p>
                                                        Our team of air charter experts are available to you 24/7, and can assist with any questions relating to the dangerous or hazardous goods you might be looking to ship
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-airport'></i>
                                                    <h4>Access to more airports</h4>
                                                    <p>
                                                        We have the ability to reach under-serviced locations, enabling you to deliver your cargo to its final destination, no matter where it needs to be.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row2// */}
                                        <div className='row'>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className='fas fa-plane'></i>
                                                    <h4>Aircraft choice</h4>
                                                    <p>
                                                        With thousands of aircraft available to us, we’re sure we’ll always secure the right one for you.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-sm-12  col-md-6'>
                                                <div className='support-data'>
                                                    <i className="fas fa-time"></i>                                                    <h4>Time-saving technology</h4>
                                                    <p>
                                                        Charter options in a matter of minutes – allowing you to stay ahead of the competition, and get your flights departing straight away.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* //row3 faq// */}


                                        <div className='rown mt-5'>
                                            <div className='col-lg-12 col-sm-12 col-md-12'>
                                                <div className="accordion faq-main" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                What are dangerous goods?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                    Items that pose a risk to the safety of an aircraft or the occupants on board are considered dangerous goods. Dangerous Goods are also known as restricted articles, hazardous materials and dangerous cargo. Many everyday household objects can be categorised as dangerous goods for the purposes of air transportation.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                I have a shipment of electronic equipment containing lithium batteries, is it classified as a dangerous good?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                    Yes, but it might not always require a formal declaration. We recommend looking at Section II of the IATA Lithium Batteries guide for more information.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                How do I know if my Lithium batteries meet the requirements of the “UN Manual of Tests and Criteria, Part III, subsection 38.3”?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body faq-data">
                                                                <p>
                                                                    Please check with the manufacturer or distributor for recent production information. Most battery manufacturers and distributors also have such information on their websites.


                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default dangerousgoods
