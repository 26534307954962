import React from 'react'
import Footer from '../../layout/footer/Footer'
import FooterOne from '../../section/footer/FooterOne'
import BannerOne from "../../../src/section/banner/BannerOne";
import bannerback from "../../../src/assets/images/bannerback.jpg";
import certificationimg from '../../../src/assets/images/Certification.jpg'
import Header from '../../section/header/Header'
// import HeaderImage from "../../../src/components/images/Images";
function certification() {

    return (
        <>
            {/* <HeaderImage/> */}
            <Header />
            <div className='service-data'>
                <div className='banner-section'>
                    <h3>Certifications</h3>
                </div>
                <div className='container'>
                    <div className='row mt-5 service-sec'>
                        <div className='col-lg-4 col-sm-12 col-md-12'>
                            <div className='service-image'>
                                <img src={certificationimg}></img>
                            </div>
                        </div>

                        <div className='col-lg-8 col-sm-12 col-md-12'>
                            <div className='service-content'>
                                <h3>Certifications</h3>
                                <p>
                                Certain destinations require certificates like Certificate of Origin (COO), Certificate of Conformity (COC), etc. Our partners are registered with International Chamber of Commerce (ICC) ,World Chambers Federation (WCF) and other agencies like SGS (Société Générale de Surveillance) and Bureau Veritas (BV) to process such certifications.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default certification
