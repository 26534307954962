import React from "react"
import BannerOne from "../section/banner/BannerOne"
import CtaOne from "../section/cta/CtaOne"
import FactsOne from "../section/facts/FactsOne"
import BrandOne from "../section/brand/BrandOne"
import FaqOne from "../section/faq/FaqOne"
import AboutOne from "../section/about/AboutOne"
import { FeatureOne, FeatureOneAlt } from "../section/feature/FeatureOne"
import FooterOne from "../section/footer/FooterOne"
import SercieOne from "../section/service/Serviceone"
import ContactUs from "./ContactUs"
// import BannerTwo from "../section/banner/BannerTwo"
import Testimonial from "../section/review/ReviewThree"
import newImg from "../../src/assets/images/IMG_0499.jpg"
import Chooseus from "../section/chooseus"
import supply from '../../src/assets/images/supplyimg.png'


const IndexOne = () => {
    return (
        <div>
            
            <div className="nk-main">
                {/* <ContactUs className="section-contact" id="contact"/> */}
                {/* <BannerOne  id="#home" /> */}
                <BannerOne className="has-header-main-s1 bg-grad-a  md-7" id="#home" />
             
                {/* section section */}
                <section className="call-to-action-two">
                    <div className="upper-box">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="title-column col-lg-8 col-md-12">
                                    <div className="inner-column wc">
                                        <div className="sec-title light mb-0">
                                            <p>Supply Chain Industry experts coming together to help you manage cargo consolidation especially time sensitive odd dimension cargo which needs to be air freighted.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="info-column col-lg-4 col-md-12">
                                    <div className="inner-column">
                                        <div className="info-box">
                                            <div className="inner">
                                                <img src={supply} className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                  
                </section>

                {/* second section end  */}

                <SercieOne className="section-service servive-container" id="#service" />
                <BrandOne className="section-brand py-6" />
                {/* <FeatureOne className="section-feature pb-0" /> */}
                {/* id of FeatureOne moved to FeatureOneAlt */}
                {/* <FeatureOneAlt className="section-feature" id="#feature" /> */}
                {/* <AboutOne className="section-about" id="#about" /> */}
                {/* <FaqOne className="section-faq" id="#faq"/> */}
                <Testimonial className="section-test" id="#test" />
                <Chooseus/>
                <CtaOne className="section-contact contact-box" id="#contact" />
                <FooterOne className="bg-indigo is-dark footermain" id="" />
            </div>

        </div>
    )
}
export default IndexOne
