import React, { useState } from "react"
import { Section, SectionHead } from "../../layout/section/Section"
import { Container, Row, Col, Card, Form, FormGroup, Input, Alert } from "reactstrap"
import { TextBlock, TitleH3 } from "../../components/textBlock/TextBlock"
import { Button, Link } from "../../components/button/Button"
import { submitContact } from "../../api/AboutUsApi"
import { NioIcon } from "../../components/icon/Icon"


const CtaOne = (props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const handleCloseAlert = () => {
        setShowSuccessAlert(false);
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        question: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('test data', formData);
        try {
            if (!isFormSubmitted) {
                let result = submitContact({ data: formData });
                result.then(
                    (resp) => {
                        console.log(resp);
                        if (resp) {
                            setIsFormSubmitted(true);
                            setShowSuccessAlert(true);
                        }
                    },
                    (err) => {
                        console.log('error', err);

                    }
                );
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-center">
                    <Col xl="10">
                        <Row className="align-items-center g-0">
                            <Col md="7">
                                <Card className="card-shadow round-xl  is-dark pb-4 pb-md-0 support-box">
                                    <div className="card-inner card-inner-xl supportbox">
                                        <TextBlock>
                                            <TitleH3 className="support-title supporthead">Join our mailing list for newsletters / articles and collaborate </TitleH3>
                                        </TextBlock>
                                        <ul className="list list-nostyle fs-16px support-list">
                                            <li>Please fill out this short form and we’ll occasionally send you emails about our technology updates, services or latest insights.</li>
                                            <li></li>
                                            <li className="support-online">Online 24/7 support</li>
                                        </ul>
                                     
                                    </div>
                                </Card>
                               
                            </Col>
                            <Col md="5" lg="5">
                                <Card className="card-shadow round-xl ml-lg-n7 ml-md-n5 mx-4 mr-md-0 mt-md-0 mt-n4">
                                    <div className="card-inner card-inner-lg">
                                        <div className="form-block">
                                            <SectionHead className="section-head-sm">
                                                <h6 className="title mb-0">Join our mailing list for newsletters / articles and collaborate </h6>
                                                <p className="pb-0"><small>Please fill out this short form and we’ll occasionally send you emails about our technology updates, services or latest insights.</small></p>
                                            </SectionHead>
                                            <Form className="form-submit" onSubmit={handleSubmit}>
                                                <Row className="">
                                                    <Col xs="6" className="mb-2">
                                                        <FormGroup>
                                                            {/* <label className="form-label formlabel" htmlFor="firstName">First Name</label> */}
                                                            <div className="form-control-wrap">
                                                                <Input type="text" value={formData.firstName}
                                                                    onChange={handleChange} className="form-control form-control-lg forminput" name="firstName" id="firstName" placeholder="First Name" />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" className="mb-2">
                                                        <FormGroup>
                                                            {/* <label className="form-label formlabel" htmlFor="lastName">Last Name</label> */}
                                                            <div className="form-control-wrap">
                                                                <Input type="text" className="form-control form-control-lg forminput" name="lastName" id="lastName" placeholder="Last Name"
                                                                    value={formData.lastName}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" className="mb-2">
                                                        <FormGroup>
                                                            {/* <label className="form-label formlabel" htmlFor="email">Enter Your Email</label> */}
                                                            <div className="form-control-wrap">
                                                                <Input type="text" className="form-control form-control-lg forminput" id="email" name="email" placeholder="Enter Your Email"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" className="mb-2">
                                                        <FormGroup>
                                                            {/* <label className="form-label formlabel" htmlFor="phone">Enter Your Phone</label> */}
                                                            <div className="form-control-wrap">
                                                                <Input type="text" className="form-control form-control-lg forminput" id="phone" name="phone" placeholder="Phone No."
                                                                    value={formData.phone}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" className="mb-2">
                                                        <FormGroup>
                                                            {/* <label className="form-label formlabel" htmlFor="question">Your Question</label> */}
                                                            <div className="form-control-wrap">
                                                                <textarea className="form-control no-resize forminput" id="question" name="question" placeholder="Your Question"
                                                                    value={formData.question}
                                                                    onChange={handleChange}></textarea>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12">

                                                        <button type="submit" className="btn-lg btn-primary form-btn">Subscribe</button>
                                                        {showSuccessAlert && (
                                                            <Alert color="success" className="alert-icon alert-sm py-1">
                                                                <span className="" style={{ fontWeight: 'bold', fontSize: '16px' }}>

                                                                    Your enquiry has been received and we will contact you at our earliest.
                                                                    <button type="button" onClick={handleCloseAlert} className="btn-close right" data-dismiss="alert" aria-label="Close" style={{ backgroundColor: 'transparent', border: 'none', color: 'green', fontSize: '20px' }}> <NioIcon icon="cross-fill-c" /></button>
                                                                </span>

                                                            </Alert>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default CtaOne

// <Alert color="success" className="alert-icon">
//                     {" "}
//                     <NioIcon icon="alert-circle" /> Form Submitted Successfully" "}
//  </Alert>
